/* eslint-disable react/jsx-one-expression-per-line */
import { faCommentAlt, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { faCommentAlt as faCommentAltReg } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@cloudez/cloudez-design-system';
import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';

import { ThemeContext } from 'styled-components';
import { io as socketIOClient } from 'socket.io-client';
import md5 from 'md5';
import { useAuth, useNotifications } from 'hooks';
import NotificationAlert from 'components/NotificationAlert';
import { getChatsService } from 'services/deadpool';
import { Wrapper, Header, Chat, SearchBox, Actions } from './styles';
import { ChatContext } from '../_context/state';
import ChatCard from './ChatCard';

const Chats = refresh => {
  const theme = useContext(ThemeContext);
  const { user, company, token } = useAuth();
  const { setSelected, selected, setNotifications } = useContext(ChatContext);
  const [filter, setFilter] = useState(1);
  const [chats, setChats] = useState([]);

  const newChat = useCallback(payload => {
    setChats(_chats => [payload, ..._chats]);
  }, []);

  useEffect(() => {
    const getChats = async () => {
      try {
        const { data } = await getChatsService({
          open: filter === 1,
          company_uuid: company.code,
          is_partner: false,
        });

        setChats(data);
      } catch (e) {
        console.log(e);
      }
    };

    getChats();
  }, [filter, refresh, company]);

  const socket = useMemo(() => {
    const socket = socketIOClient(
      `${process.env.REACT_APP_DEADPOOL_URL}/company`,
      {
        query: {
          company: company.code,
        },
        auth: {
          token,
        },
      },
    );

    socket.on('new chat', data => {
      newChat(data);

      const hash = md5(data);
      const notification = {
        ...data,
        id: `${data.id}-${hash}`,
        description: `Novo chat #${data.id}`,
        resource_id: data.id,
        status: 0,
      };

      setNotifications(nots => [...nots, notification]);
    });
  }, [token, company]);

  return (
    <Wrapper>
      <Header>
        <Actions>
          <Button icon>
            <FontAwesomeIcon icon={faVolumeUp} />
          </Button>
          <FontAwesomeIcon
            icon={faCommentAltReg}
            size="2x"
            color={filter === 1 ? theme.brand_primary_color : theme.interface4}
            onClick={() => setFilter(1)}
          />
          <FontAwesomeIcon
            icon={faCommentAlt}
            size="2x"
            color={filter === 2 ? theme.brand_primary_color : theme.interface4}
            onClick={() => setFilter(2)}
          />
        </Actions>
        {/* <SearchBox>
          <SearchInput width="100%" />
        </SearchBox> */}
      </Header>

      <ul>
        {chats?.map(c => (
          <ChatCard c={c} />
        ))}
      </ul>
    </Wrapper>
  );
};

export default Chats;
