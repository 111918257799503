import {
  faArrowUp,
  faEdit,
  faPaperclip,
  faWindowClose,
  faSpinner,
  faPaperPlane,
  faSmile,
  faFilePdf,
  faFile,
  faEllipsisV,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EmptyState } from 'components/EmptyState';
import { TextArea } from 'components/Input';
import { Picker } from 'emoji-mart';
import { useAuth, useLayout, useNotifications } from 'hooks';
import useDevice from 'hooks/device';
import { io as socketIOClient } from 'socket.io-client';
import md5 from 'md5';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  createMessageService,
  getChatService,
  readNotificationService,
} from 'services/deadpool';
import { ChatContext } from '../_context/state';
import CloseModal from './CloseModal';
import Message from './Message';
import { File } from './Message/styles';
import ScaleModal from './ScaleModal';
import {
  Header,
  Messages,
  Footer,
  Client,
  Actions,
  NoTicket,
  Icons,
  Typing,
  Files,
  MobileMenu,
} from './styles';

interface IConversationProps {
  setRefresh?: (value: boolean) => void;
  refresh?: boolean;
}

const Conversation: React.FC<IConversationProps> = ({
  setRefresh,
  refresh,
}) => {
  const { selected, setSelected, notifications, setNotifications } =
    useContext(ChatContext);
  const { isMobile } = useDevice();
  const theme = useLayout();

  const { user, token } = useAuth();

  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [messageLoading, setMessageLoading] = useState(false);
  const [typingUsers, setTypingUsers] = useState([]);
  const [hasFile, setHasFile] = useState(false);
  const [files, setFiles] = useState(false);
  const [allFiles, setAllFiles] = useState([]);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [scaleModal, setScaleModal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [chat, setChat] = useState(null);

  const textRef = useRef<HTMLTextAreaElement>();
  const fileInput = useRef<any>();

  const socket = useMemo(() => {
    const socket = socketIOClient(
      `${process.env.REACT_APP_DEADPOOL_URL}/chat`,
      {
        query: {
          chat: selected,
        },
        auth: {
          token,
        },
      },
    );

    socket?.on('message', ({ message }) => {
      setMessages(data => [...data, message]);
    });

    socket?.on('typing', data => {
      setTypingUsers(_users => {
        if (_users.find(u => data.user === u.user)) {
          return _users.map(u => (u.user === data.user ? data : u));
        }
        return [..._users, data];
      });
    });

    return socket;
  }, [selected, token]);

  useEffect((): any => {
    async function getTicketEffect(id) {
      setLoading(true);
      if (id) {
        try {
          const { data } = await getChatService(id);

          setChat(data);
          setMessages(data.messages);

          if (textRef.current) textRef.current.value = '';
          setLoading(false);
        } catch (e) {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }

    getTicketEffect(selected);

    return () => socket?.close();
  }, [selected, socket]);

  const onChange = useCallback(
    e => {
      if (e.target.value.length === 1) {
        socket?.emit('typing', {
          user: user.uuid,
          user_name: user.full_name,
          typing: true,
        });
      } else if (e.target.value.length <= 0) {
        socket?.emit('typing', {
          user: user.uuid,
          user_name: user.full_name,
          typing: false,
        });
      }
    },
    [user, socket],
  );

  useEffect(() => {
    const notification = notifications?.filter(
      n =>
        n &&
        (n.resource_id === selected || n.chat_id === selected) &&
        n.status === 0 &&
        n.description !== '',
    );

    if (notification?.length > 0 && selected) {
      const _not = notification[0];
      readNotificationService(_not?.id);

      const newNots = notifications.map(not =>
        not.id === _not?.id ? { ...not, status: 1 } : not,
      );

      setNotifications(newNots);
    }
  }, [notifications, selected]);

  const addMessage = useCallback(async () => {
    if (messageLoading) return;
    setMessageLoading(true);

    if (textRef.current && selected)
      try {
        const messagePayload = new FormData() as any;

        messagePayload.append('chat_id', selected);
        messagePayload.append('text', textRef.current.value);
        messagePayload.append('author_uuid', user.uuid);
        messagePayload.append('author_email', user.email);
        messagePayload.append('author_name', user.full_name);
        messagePayload.append('is_staff', true);

        if (fileInput.current?.files) {
          if (fileInput.current?.files?.length > 0)
            messagePayload.append('file', fileInput.current?.files[0]);

          fileInput.current.value = '';
          setHasFile(false);
        }

        await createMessageService(messagePayload);

        textRef.current.value = '';
        setMessageLoading(false);
        socket?.emit('typing', {
          user: user.uuid,
          user_name: user.full_name,
          typing: false,
        });
      } catch (e) {
        setMessageLoading(false);
        console.log(e);
      }
  }, [textRef, selected, user, fileInput, messageLoading, socket]);

  const onkeydownHandler = useCallback(
    e => {
      if (e.ctrlKey && e.keyCode === 13) {
        addMessage();
      }
    },
    [addMessage],
  );

  return (
    <>
      {chat ? (
        <>
          {chat?.open && (
            <>
              {scaleModal && (
                <ScaleModal
                  show={scaleModal}
                  setShow={() => setScaleModal(false)}
                  ticket={selected}
                  callback={() => {
                    setRefresh(!refresh);
                    setSelected(null);
                  }}
                />
              )}
              {closeModal && (
                <CloseModal
                  show={closeModal}
                  setShow={() => setCloseModal(false)}
                  ticket={selected}
                  callback={() => {
                    setRefresh(!refresh);
                    setSelected(null);
                  }}
                />
              )}
              <Header>
                <>
                  <Client>
                    <img
                      src={`https://www.gravatar.com/avatar/${md5(
                        chat.owner_email,
                      )}?&d=mp`}
                      alt="avatar"
                    />

                    <div>
                      <span className="owner">{chat.owner_name}</span>
                      <span className="summary">{chat.summary}</span>
                    </div>
                  </Client>
                  <Actions>
                    {chat?.open && !isMobile && (
                      <>
                        <FontAwesomeIcon
                          title="Fechar atendimento"
                          className="close"
                          icon={faWindowClose}
                          onClick={() => setCloseModal(true)}
                        />
                        <FontAwesomeIcon
                          icon={faArrowUp}
                          onClick={() => setScaleModal(true)}
                        />
                      </>
                    )}
                    <FontAwesomeIcon
                      icon={faPaperclip}
                      onClick={() => {
                        setMobileMenu(false);
                        setFiles(state => !state);
                      }}
                    />

                    {isMobile && (
                      <>
                        <FontAwesomeIcon
                          icon={faEllipsisV}
                          onClick={() => {
                            setFiles(false);
                            setMobileMenu(_value => !_value);
                          }}
                        />
                      </>
                    )}

                    {mobileMenu && (
                      <MobileMenu>
                        <ul>
                          <li
                            onClick={() => {
                              setMobileMenu(false);
                            }}
                          >
                            <div className="icon">
                              <FontAwesomeIcon
                                icon={faArrowUp}
                                onClick={() => setScaleModal(true)}
                              />
                            </div>

                            <span>Escalar</span>
                          </li>
                          <li
                            onClick={() => {
                              setMobileMenu(false);
                            }}
                          >
                            <div className="icon">
                              <FontAwesomeIcon
                                icon={faWindowClose}
                                className="close"
                                onClick={() => setCloseModal(true)}
                              />
                            </div>

                            <span>Fechar</span>
                          </li>
                        </ul>
                      </MobileMenu>
                    )}

                    {files && (
                      <Files>
                        {allFiles.length > 0 ? (
                          allFiles?.map(f => {
                            const hasImage =
                              f.includes('.png') ||
                              f.includes('.jpg') ||
                              f.includes('.jpe') ||
                              f.includes('.jpeg');

                            return (
                              <File key={f.id}>
                                <span
                                  onClick={() => {
                                    window.open(f, '_blank');
                                    setFiles(false);
                                  }}
                                >
                                  {hasImage ? (
                                    <img src={f} alt={f} />
                                  ) : (
                                    <FontAwesomeIcon
                                      icon={
                                        f.includes('.pdf') ? faFilePdf : faFile
                                      }
                                    />
                                  )}
                                </span>
                                <p>{f.split('uploads/')[1]}</p>
                              </File>
                            );
                          })
                        ) : (
                          <p
                            style={{
                              marginTop: '10px',
                              textAlign: 'center',
                              color: theme.interface5,
                            }}
                          >
                            Sem anexo
                          </p>
                        )}
                      </Files>
                    )}
                  </Actions>
                </>
              </Header>
            </>
          )}
          <Messages
            ref={ref => {
              if (ref) {
                ref.scrollTo({
                  top: ref.scrollHeight,
                  behavior: 'smooth',
                });
              }
            }}
          >
            {loading ? (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <FontAwesomeIcon
                  color={theme.interface5}
                  icon={faSpinner}
                  spin
                  size="3x"
                />
              </div>
            ) : (
              <>
                {messages.map(m => (
                  <Message message={m} key={m.id} />
                ))}

                {typingUsers?.find(u => u.typing) && (
                  <Typing>
                    {typingUsers.map(
                      (u, i) =>
                        u.typing && (
                          <p key={u.id}>
                            {u.user_name}
                            {typingUsers.length > 1 &&
                            i + 1 !== typingUsers.length
                              ? ', '
                              : ' '}
                          </p>
                        ),
                    )}
                  </Typing>
                )}
              </>
            )}
          </Messages>

          {chat?.open && (
            <Footer>
              <input
                ref={fileInput}
                type="file"
                id="fileinput"
                style={{
                  display: 'none',
                }}
                onChange={e => setHasFile(!!e?.target?.files?.length)}
              />

              <div className="textarea">
                <TextArea
                  ref={textRef}
                  placeholder="Digite aqui sua mensagem"
                  block
                  height="auto"
                  onKeyDown={onkeydownHandler}
                  onChange={onChange}
                  disabled={loading}
                />

                <FontAwesomeIcon
                  icon={messageLoading ? faSpinner : faPaperPlane}
                  spin={messageLoading}
                  onClick={addMessage}
                />
              </div>

              <Icons hasFile={hasFile}>
                <FontAwesomeIcon
                  className="file"
                  icon={faPaperclip}
                  onClick={() => fileInput?.current?.click()}
                />
                <FontAwesomeIcon
                  icon={faSmile}
                  onClick={() => {
                    if (chat.status === 2) return;
                    setShowEmojiPicker(!showEmojiPicker);
                  }}
                />
              </Icons>

              {showEmojiPicker && (
                <div
                  style={{
                    position: 'absolute',
                    zIndex: 2,
                  }}
                >
                  <div
                    style={{
                      position: 'fixed',
                      top: '0px',
                      right: '0px',
                      bottom: '0px',
                      left: '0px',
                    }}
                    onClick={() => setShowEmojiPicker(false)}
                  />
                  <Picker
                    onSelect={emoji => {
                      if (textRef?.current) {
                        textRef.current.value = `${textRef?.current?.value}${emoji}`;
                        setShowEmojiPicker(false);
                      }
                    }}
                    style={{
                      position: 'absolute',
                      left: '0px',
                      bottom: '-25px',
                    }}
                  />
                </div>
              )}
            </Footer>
          )}
        </>
      ) : (
        <NoTicket>
          <EmptyState text="Nenhuma mensagem por enquanto, aproveite para relaxar um pouco" />
        </NoTicket>
      )}
    </>
  );
};

export default Conversation;
