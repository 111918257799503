import React, { useCallback, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faSpinner } from '@fortawesome/free-solid-svg-icons';

import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Footer,
} from 'components/ListingModal/styles';
import Modal from 'components/Modal';
import { Button } from '@cloudez/cloudez-design-system';
import { toast } from 'react-toastify';
import { closeChatService } from 'services/deadpool';

const CloseModal = ({ ticket, show, setShow, callback }) => {
  const [loading, setLoading] = useState(false);

  const closeChat = useCallback(async () => {
    setLoading(true);
    try {
      await closeChatService(ticket);

      callback();

      setLoading(false);
      setShow();
      toast.success('Chat fechado com sucesso');
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }, [ticket]);

  return (
    <Modal show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faArrowUp} />
        </Icon>
        <Text>
          <Title>Fechar chat</Title>
          <Subtitle>Deseja fechar este chat?</Subtitle>
        </Text>
      </Header>

      <Footer>
        <Button
          disabled={loading}
          icon={loading ? faSpinner : undefined}
          onClick={closeChat}
        >
          {!loading && 'Fechar'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default CloseModal;
