import React, { useCallback, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faSpinner } from '@fortawesome/free-solid-svg-icons';

import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Footer,
} from 'components/ListingModal/styles';
import Modal from 'components/Modal';
import { Button } from '@cloudez/cloudez-design-system';
import { toast } from 'react-toastify';
import { escalateToTicketService } from 'services/deadpool';

const ScaleModal = ({ ticket, show, setShow, callback }) => {
  const [loading, setLoading] = useState(false);

  const scaleChat = useCallback(async () => {
    setLoading(true);
    try {
      await escalateToTicketService(ticket);

      setLoading(false);
      setShow();

      toast.success('Chat escalado com sucesso');
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }, [ticket]);

  return (
    <Modal show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faArrowUp} />
        </Icon>
        <Text>
          <Title>Escalar chat</Title>
          <Subtitle>Deseja escalar este chat para N2?</Subtitle>
        </Text>
      </Header>

      <Footer>
        <Button
          disabled={loading}
          icon={loading ? faSpinner : undefined}
          onClick={scaleChat}
        >
          {!loading && 'Escalar'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default ScaleModal;
