import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';

import { Field, Label, Value } from 'components/DetailConfig';
import { MigrationDetailContext } from '../_context/state';

const Acessos = () => {
  const { migration } = useContext(MigrationDetailContext);

  const migInfo = migration?.cache_data?.extra_args;

  return (
    migration && (
      <Row>
        <Col xl>
          {migInfo.accounts.map(a => (
            <Row key={a.username}>
              <Col>
                <Field>
                  <Label>Email</Label>
                  <Value>{a.username}</Value>
                </Field>
              </Col>
              <Col>
                <Field>
                  <Label>Senha</Label>
                  <Value>{a.password}</Value>
                </Field>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    )
  );
};

export default Acessos;
