import React, { useState, useEffect, useRef } from 'react';
import InputMask from 'react-input-mask';

import Axios from 'axios';
import * as yup from 'yup';

import { cpf } from 'cpf-cnpj-validator';

import { Row, Col } from 'react-bootstrap';
import {
  Input,
  Error as StyledError,
  Checkbox,
  Radio,
  Label,
} from 'components/Input';

import {
  getBankAccountService,
  getCNPJDataService,
  getCompanyBillingService,
  updateBankAccountService,
  updateBillingService,
} from 'services/common';

import StyledSelect from 'components/SearchSelect';

import { SearchWrapper } from 'components/SearchBar/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faMinus,
  faSearch,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { Button, FormSelect } from '@cloudez/cloudez-design-system';
import { toast } from 'react-toastify';
import { banks, masks } from './variables';

import UpdateModal from './UpdateModal';

import {
  ContentWrapper,
  ContainerWrapper,
  Title,
  Field,
  RadioBox,
  FooterWrapper,
  Icon,
} from './styles';

interface IFormProps {
  company: any;
  modal?: any;
  onUpdateBillingSuccess?: () => void;
  tab?: number;
}

const Form: React.FC<IFormProps> = ({
  company,
  modal,
  tab,
  onUpdateBillingSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const [bankLoading, setBankLoading] = useState(false);
  const [billingLoading, setBillingLoading] = useState(false);
  const [cnpj, setCNPJ] = useState('');
  const [notHasIe, setNotHasIe] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [noEdit, setNoEdit] = useState(false);
  const [noEditBank, setNoEditBank] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const [billing, setBilling] = useState({} as any);

  const [billingErrors, setBillingErrors] = useState({} as any);

  const [errors, setErrors] = useState({} as any);

  const [noCNPJ, setNoCNPJ] = useState(false);

  const [bankAccount, setBankAccount] = useState({} as any);

  const fileInputIdentification = useRef(null);
  const fileInputSelfie = useRef(null);
  const fileInputAddressProof = useRef(null);
  const fileInputBalanceSheet = useRef(null);
  const fileInputSocialContract = useRef(null);

  const [uploadedFiles, setUploadedFiles] = useState({} as any);

  const sortedBanks = banks.sort((a, b) => {
    return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
  });

  const el = sortedBanks[15];
  sortedBanks.splice(15, 1);
  sortedBanks.splice(0, 0, el);

  const billingSchema = yup.object().shape({
    responsible_name: yup
      .string()
      .required('O nome do responsável é obrigatório.'),
    responsible_cpf: yup
      .mixed()
      .test(
        'responsible_cpf',
        billing.responsible_cpf
          ? 'CPF inválido!'
          : 'O CPF do responsável é obrigatório.',
        value => {
          console.log('running validations');
          return value && cpf.isValid(billing.responsible_cpf);
        },
      ),
    phone_number: yup.string().required('O número de telefone é obrigatório.'),
    fantasy_name: yup.string().required('O nome da empresa é obrigatório.'),
    zipcode: yup.string().required('O CEP é obrigatório.'),
    address1: yup.string().required('O endereço é obrigatório.'),
    address2: yup.string().required('O bairro é obrigatório.'),
    address_number: yup
      .string()
      .required()
      .typeError('O número é obrigatório.'),
    city: yup.string().required('O município é obrigatório.'),
    state: yup.string().required('O estado é obrigatório.'),
    business_name: yup.string().required('A razão social é obrigatória.'),
    document_id: yup.string().required('O CNPJ é obrigatório.'),
    inscricao_estadual: !noCNPJ
      ? yup.string().required('A inscrição estadual é obrigatória.')
      : yup.string(),
  });

  const bankAccountSchema = yup.object().shape({
    bank: yup.string().required().typeError('O banco é obrigatório.'),
    bank_cc: yup.string().required('O dígito é obrigatório.'),
    bank_ag: yup.string().required('A agência é obrigatória.'),
    bank_account_type: yup
      .string()
      .required()
      .typeError('O tipo de conta é obrigatório.'),
    bank_person_type: yup
      .string()
      .required()
      .typeError('O tipo de pessoa é obrigatório.'),
    identification: yup
      .string()
      .required('O documento de identificação é obrigatório.'),
    selfie: yup.string().required('A selfie é obrigatória.'),
    address_proof: yup
      .string()
      .required('O comprovante de endereço é obrigatório.'),

    balance_sheet: yup.string().when('bank_person_type', {
      is: '2',
      then: yup.string().required('O faturamento é obrigatório.'),
      otherwise: yup.string().notRequired(),
    }),
    social_contract: yup.string().when('bank_person_type', {
      is: '2',
      then: yup.string().required('O contrato social é obrigatório.'),
      otherwise: yup.string().notRequired(),
    }),
  });

  useEffect(() => {
    const getBilling = async () => {
      try {
        const { data } = await getCompanyBillingService(company.code);

        setBilling({
          ...data,
          phone_number: data?.phone_number?.substring('+55'.length),
        });

        if (data?.inscricao_estadual === 'ISENTO') {
          setNotHasIe(true);
        }

        if (data.document_id) {
          if (data.document_id.length >= 14) {
            setCNPJ(data.document_id);
          } else {
            setNoCNPJ(true);
          }
        }

        // setNoEdit(data.split_account_status === 'active');
        setNoEdit(!!data.document_id);

        setBillingLoading(false);
      } catch (e) {
        setBillingLoading(false);
        console.log(e);
      }
    };

    const getBankAccount = async () => {
      try {
        const { data } = await getBankAccountService(company.code);

        setBankAccount(data);

        setNoEditBank(!!data.bank_person_type);
      } catch (e) {
        console.log(e);
      }
    };

    getBilling();
    getBankAccount();
    // eslint-disable-next-line
  }, []);

  const getCEP = async _cep => {
    try {
      setLoading(true);
      const { data } = await Axios.get(
        `https://viacep.com.br/ws/${_cep}/json/`,
      );

      if (data.status === 'ERROR') {
        setNoResults(true);
        setLoading(false);
        return;
      }

      setBilling({
        ...billing,
        zipcode: _cep,
        state: data.uf,
        city: data.localidade,
        address1: data.logradouro,
        address2: data.bairro,
      });

      setNoResults(false);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const getCNPJData = async _cnpj => {
    try {
      setLoading(true);
      const { data } = await getCNPJDataService(_cnpj);
      if (data.status === 'ERROR') {
        setNoResults(true);
        setLoading(false);
        return;
      }
      setLoading(false);
      setBilling({
        ...billing,
        zipcode: data.cep,
        state: data.uf,
        city: data.municipio,
        country: 'Brasil',
        address_number: data.numero,
        address1: data.logradouro,
        address2: data.bairro,
        fantasy_name: data.fantasia,
        business_name: data.nome,
        document_id: data.cnpj,
      });
      setNoResults(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const updateBilling = () => {
    setBillingErrors([]);
    setBillingLoading(true);

    billingSchema
      .validate(billing, { abortEarly: false })
      .then(async result => {
        const payload = {
          ...result,
          document_id: result?.document_id?.replace(/\.|-|\//g, ''),
          responsible_cpf: result?.responsible_cpf?.replace(/\.|-|\//g, ''),
          phone_number: `+55${result?.phone_number?.replace(/\.|-|\//g, '')}`,
          country: 'Brasil',
        };
        if (cpf.isValid(billing.responsible_cpf)) {
          await updateBillingService(company.code, payload);
          setBillingErrors([]);
          setNoEdit(true);
          setNoEditBank(false);
          toast.success('Os dados foram atualizados com sucesso!');
        } else {
          setBillingErrors({
            responsible_cpf: 'CPF inválido!',
          });
        }

        if (modal) {
          onUpdateBillingSuccess();
        }
        setBillingLoading(false);
      })
      .catch(error => {
        if (error.inner) {
          setBillingErrors(
            error.inner?.reduce((obj, item) => {
              return {
                ...obj,
                [item.params.path]: item.message,
              };
            }, []),
          );
        } else {
          setBillingErrors(error?.response?.data);
        }
        setBillingLoading(false);
      });
  };

  const updateBankAccount = () => {
    setBankLoading(true);
    setErrors([]);

    bankAccountSchema
      .validate(bankAccount, { abortEarly: false })
      .then(async result => {
        await updateBankAccountService(company.code, result);
        setErrors([]);
        setNoEditBank(true);
        toast.success('Os dados foram atualizados com sucesso!');
        setBankLoading(false);
      })
      .catch(error => {
        if (error.inner) {
          setErrors(
            error.inner.reduce((obj, item) => {
              return {
                ...obj,
                [item.params.path]: item.message,
              };
            }, []),
          );
        }
        toast.error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0],
        );
        setBankLoading(false);
      });
  };

  const deleteCallback = () => {
    setNoEdit(false);
    setNoEditBank(false);
  };

  const handleFileUpload = async (e, fieldName) => {
    const file = e.target.files[0];
    if (!file) return;

    const toBase64 = file =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });

    try {
      const base64 = await toBase64(file);

      setUploadedFiles(prevState => ({
        ...prevState,
        [fieldName]: file.name,
      }));

      setBankAccount(prevState => ({
        ...prevState,
        [fieldName]: base64,
      }));
    } catch (error) {
      console.error('Erro ao converter arquivo para Base64', error);
    }
  };

  return (
    <ContainerWrapper>
      <ContentWrapper isModal={modal}>
        {updateModal && (
          <UpdateModal
            code={company.code}
            show={updateModal}
            setShow={setUpdateModal}
            action={deleteCallback}
          />
        )}
        <Row>
          <Col>
            <Title>Dados Gerais</Title>
          </Col>
        </Row>
        <Row style={{ marginBottom: '22px' }}>
          <Col xl="6">
            <Field>
              <Label>Nome do responsável</Label>

              <Input
                name="responsible_name"
                block
                value={billing?.responsible_name}
                maxLength={255}
                disabled={noEdit}
                background={noEdit}
                onChange={e => {
                  setBilling({
                    ...billing,
                    responsible_name: e.target.value,
                  });
                }}
                error={billingErrors?.responsible_name}
              />
              {billingErrors?.responsible_name && (
                <StyledError>{billingErrors.responsible_name}</StyledError>
              )}
            </Field>
          </Col>

          <Col xl="3">
            <Field>
              <Label>CPF do responsável</Label>
              <InputMask
                name="responsible_cpf"
                mask="999.999.999-99"
                maskChar={null}
                disabled={noEdit}
                background={noEdit}
                block="true"
                value={billing?.responsible_cpf}
                onChange={e => {
                  if (noCNPJ) {
                    setBilling(billing => ({
                      ...billing,
                      document_id: e.target.value,
                    }));
                  }
                  setBilling(billing => ({
                    ...billing,
                    responsible_cpf: e.target.value,
                  }));
                }}
                error={billingErrors?.responsible_cpf}
              >
                {inputProps => <Input disabled={noEdit} {...inputProps} />}
              </InputMask>
              {billingErrors?.responsible_cpf && (
                <StyledError>{billingErrors.responsible_cpf}</StyledError>
              )}
            </Field>
          </Col>
          <Col xl="3">
            <Field>
              <Label>Número de telefone</Label>
              <InputMask
                mask="(99) 99999-9999"
                disabled={noEdit}
                name="phone_number"
                background={noEdit}
                maskChar={null}
                block="true"
                value={billing?.phone_number}
                onChange={e => {
                  setBilling({
                    ...billing,
                    phone_number: e.target.value,
                  });
                }}
                error={billingErrors?.phone_number}
              >
                {inputProps => {
                  return <Input disabled={noEdit} {...inputProps} />;
                }}
              </InputMask>
              {billingErrors?.phone_number && (
                <StyledError>{billingErrors.phone_number}</StyledError>
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col>
            <Title>Dados da Empresa</Title>
          </Col>
        </Row>
        <Row>
          <Col style={{ marginBottom: '16px' }}>
            <Row style={{ marginLeft: '0' }}>
              <Label color="#0052EF">Selecione o tipo</Label>
            </Row>
            <Row style={{ marginLeft: '0' }}>
              <RadioBox>
                <Radio
                  checked={!noCNPJ}
                  disabled={noEdit}
                  onChange={() => {
                    setNoCNPJ(false);
                  }}
                />
                <span className="radioText">Pessoa Jurídica</span>
              </RadioBox>
              <RadioBox>
                <Radio
                  checked={noCNPJ}
                  disabled={noEdit}
                  onChange={() => {
                    setNoCNPJ(true);
                    setBilling(state => ({
                      ...state,
                      document_id: billing?.responsible_cpf,
                    }));
                  }}
                />
                <span className="radioText">Pessoa Física</span>
              </RadioBox>
            </Row>
          </Col>
        </Row>
        <Row>
          {!noCNPJ && (
            <Col xl="3">
              <Field>
                <Label>CNPJ</Label>

                <InputMask
                  mask="99.999.999/9999-99"
                  value={noCNPJ ? '' : cnpj}
                  maskChar={null}
                  onChange={e => {
                    setCNPJ(e.target.value);
                    if (e.target.value.length >= 18) {
                      // eslint-disable-next-line
                      let _cnpj = e.target.value.replace(/\.|\-|\//g, '');
                      getCNPJData(_cnpj);
                      setBilling({ ...billing, document_id: _cnpj });
                    }
                  }}
                  error={billingErrors?.document_id}
                  block="true"
                >
                  {inputProps => {
                    return (
                      <SearchWrapper onboard noResults={noResults} block>
                        <FontAwesomeIcon
                          icon={loading ? faSpinner : faSearch}
                          spin={loading}
                          onClick={() => {
                            if (!loading) {
                              // eslint-disable-next-line
                              const _cnpj = cnpj.replace(/\.|\-|\//g, '');
                              getCNPJData(_cnpj);
                            }
                          }}
                        />
                        <Input
                          {...inputProps}
                          disabled={noCNPJ || noEdit}
                          background={noCNPJ || noEdit}
                          style={{ paddingLeft: '38px' }}
                          error={noResults || billingErrors.document_id}
                        />
                      </SearchWrapper>
                    );
                  }}
                </InputMask>

                {billingErrors?.document_id && (
                  <StyledError>{billingErrors.document_id}</StyledError>
                )}
              </Field>
            </Col>
          )}
          {!noCNPJ && (
            <Col xl="3">
              <Field>
                <Label>Inscrição estadual</Label>
                <Input
                  block
                  background={noEdit}
                  value={billing?.inscricao_estadual}
                  onChange={e => {
                    setBilling({
                      ...billing,
                      inscricao_estadual: e.target.value,
                    });
                  }}
                  disabled={notHasIe}
                  error={billingErrors.inscricao_estadual}
                />
                {billingErrors?.inscricao_estadual && (
                  <StyledError style={{ marginBottom: '1px' }}>
                    {billingErrors.inscricao_estadual}
                  </StyledError>
                )}
                <RadioBox
                  style={{
                    marginTop: '8px',
                    justifyContent: 'space-between',
                    marginBottom: '16px',
                  }}
                >
                  <Label
                    style={{
                      fontSize: '11px',
                      marginBottom: '0px',
                      fontWeight: 'normal',
                      color: '#A2A9BF',
                    }}
                  >
                    Não possuo inscrição estadual *
                  </Label>
                  <Checkbox
                    checked={notHasIe}
                    value={notHasIe}
                    disabled={noEdit}
                    onChange={e => {
                      setNotHasIe(e.target.checked);

                      if (e.target.checked === true) {
                        setBilling({
                          ...billing,
                          inscricao_estadual: 'ISENTO',
                        });
                      } else {
                        setBilling({
                          ...billing,
                          inscricao_estadual: '',
                        });
                      }
                    }}
                  />
                </RadioBox>
              </Field>
            </Col>
          )}
          <Col xl={!noCNPJ ? '3' : '5'}>
            <Field>
              <Label>Nome da empresa</Label>
              <Input
                block
                name="fantasy_name"
                value={billing.fantasy_name}
                disabled={noCNPJ ? noEdit : !billing.document_id || noEdit}
                background={noCNPJ ? noEdit : !billing.document_id || noEdit}
                error={billingErrors.fantasy_name}
                onChange={e => {
                  if (noCNPJ)
                    setBilling(billing => ({
                      ...billing,
                      business_name: e.target.value,
                    }));
                  setBilling(billing => ({
                    ...billing,
                    fantasy_name: e.target.value,
                  }));
                }}
              />
              {billingErrors?.fantasy_name && (
                <StyledError>{billingErrors.fantasy_name}</StyledError>
              )}
            </Field>
          </Col>
          {!noCNPJ && (
            <Col xl="3">
              <Field>
                <Label>Razão Social</Label>
                <Input
                  block
                  name="business_name"
                  value={billing.business_name}
                  disabled={!billing.document_id || noEdit}
                  background={!billing.document_id || noEdit}
                  onChange={e =>
                    setBilling({
                      ...billing,
                      business_name: e.target.value,
                    })
                  }
                  error={billingErrors.business_name}
                />
                {billingErrors?.business_name && (
                  <StyledError>{billingErrors.business_name}</StyledError>
                )}
              </Field>
            </Col>
          )}

          <Col xl={!noCNPJ ? '3' : '2'}>
            <Field>
              <Label>CEP</Label>

              <InputMask
                mask="99999-999"
                name="zipcode"
                maskChar={null}
                alwaysShowMask="true"
                loading={loading}
                value={billing.zipcode}
                onChange={e => {
                  setBilling({
                    ...billing,
                    zipcode: e.target.value,
                  });
                  if (e.target.value.length >= 9) {
                    const _cep = e.target.value.replace(
                      // eslint-disable-next-line
                      /\.|\-|\//g,
                      '',
                    );
                    getCEP(_cep);
                  }
                }}
                block="true"
              >
                {inputProps => {
                  return (
                    <SearchWrapper onboard block>
                      <FontAwesomeIcon
                        icon={loading ? faSpinner : faSearch}
                        spin={loading}
                        onClick={
                          loading
                            ? null
                            : () => {
                                getCEP(billing.zipcode);
                              }
                        }
                      />
                      <Input
                        {...inputProps}
                        disabled={
                          noCNPJ ? noEdit : !billing.document_id || noEdit
                        }
                        background={
                          noCNPJ ? noEdit : !billing.document_id || noEdit
                        }
                        style={{ paddingLeft: '38px' }}
                        error={billingErrors?.zipcode}
                      />
                    </SearchWrapper>
                  );
                }}
              </InputMask>
              {billingErrors?.zipcode && (
                <StyledError>{billingErrors.zipcode}</StyledError>
              )}
            </Field>
          </Col>
          <Col xl="5">
            <Field>
              <Label>Endereço</Label>
              <Input
                block
                name="address1"
                disabled={
                  billing.zipcode?.replace('-', '').length < 8 || noEdit
                }
                background={
                  noEdit || billing.zipcode?.replace('-', '').length < 8
                }
                value={billing.address1}
                onChange={e =>
                  setBilling({
                    ...billing,
                    address1: e.target.value,
                  })
                }
                error={billingErrors?.address1}
              />
              {billingErrors?.address1 && (
                <StyledError>{billingErrors.address1}</StyledError>
              )}
            </Field>
          </Col>
          <Col xl="1">
            <Field>
              <Label>Número</Label>
              <Input
                block
                name="address_number"
                disabled={
                  billing.zipcode?.replace('-', '').length < 8 || noEdit
                }
                background={
                  noEdit || billing.zipcode?.replace('-', '').length < 8
                }
                value={billing.address_number}
                onChange={e =>
                  setBilling({
                    ...billing,
                    address_number: e.target.value,
                  })
                }
                error={billingErrors?.address_number}
              />
              {billingErrors?.address_number && (
                <StyledError>{billingErrors.address_number}</StyledError>
              )}
            </Field>
          </Col>
          <Col xl="3">
            <Field>
              <Label>Bairro</Label>
              <Input
                block
                name="address2"
                value={billing.address2}
                disabled={
                  billing.zipcode?.replace('-', '').length < 8 || noEdit
                }
                background={
                  noEdit || billing.zipcode?.replace('-', '').length < 8
                }
                onChange={e =>
                  setBilling({
                    ...billing,
                    address2: e.target.value,
                  })
                }
                error={billingErrors?.address2}
              />
              {billingErrors?.address2 && (
                <StyledError>{billingErrors.address2}</StyledError>
              )}
            </Field>
          </Col>
          <Col xl="3">
            <Field>
              <Label>Município</Label>
              <Input
                block
                name="city"
                value={billing.city}
                disabled={
                  billing.zipcode?.replace('-', '').length < 8 || noEdit
                }
                background={
                  noEdit || billing.zipcode?.replace('-', '').length < 8
                }
                onChange={e =>
                  setBilling({
                    ...billing,
                    city: e.target.value,
                  })
                }
                error={billingErrors?.city}
              />
              {billingErrors?.city && (
                <StyledError>{billingErrors.city}</StyledError>
              )}
            </Field>
          </Col>
          <Col xl="2">
            <Field>
              <Label>Estado</Label>
              <FormSelect
                options={[
                  { title: 'Acre', id: 'AC' },
                  { title: 'Alagoas', id: 'AL' },
                  { title: 'Amapá', id: 'AP' },
                  { title: 'Amazonas', id: 'AM' },
                  { title: 'Bahia', id: 'BA' },
                  { title: 'Ceará', id: 'CE' },
                  { title: 'Distrito Federal', id: 'DF' },
                  { title: 'Espírito Santo', id: 'ES' },
                  { title: 'Goias', id: 'GO' },
                  { title: 'Maranhão', id: 'MA' },
                  { title: 'Mato Grosso', id: 'MT' },
                  { title: 'Mato Grosso do Sul', id: 'MS' },
                  { title: 'Minas Gerais', id: 'MG' },
                  { title: 'Pará', id: 'PA' },
                  { title: 'Paraíba', id: 'PB' },
                  { title: 'Paraná', id: 'PR' },
                  { title: 'Pernambuco', id: 'PE' },
                  { title: 'Piauí', id: 'PI' },
                  { title: 'Rio de Janeiro', id: 'RJ' },
                  { title: 'Rio Grande do Norte', id: 'RN' },
                  { title: 'Rio Grande do Sul', id: 'RS' },
                  { title: 'Rondônia', id: 'RO' },
                  { title: 'Roraima', id: 'RR' },
                  { title: 'Santa Catarina', id: 'SC' },
                  { title: 'São Paulo', id: 'SP' },
                  { title: 'Sergipe', id: 'SE' },
                  { title: 'Tocantins', id: 'TO' },
                ]}
                block
                name="state"
                disabled={
                  billing.zipcode?.replace('-', '').length < 8 || noEdit
                }
                value={billing.state}
                onChange={e =>
                  setBilling({
                    ...billing,
                    state: e.target.value,
                  })
                }
                error={billingErrors?.state}
              />
              {billingErrors?.state && (
                <StyledError>{billingErrors.state}</StyledError>
              )}
            </Field>
          </Col>
        </Row>
      </ContentWrapper>
      {!modal && (
        <ContentWrapper>
          <>
            <Row>
              <Col>
                <Title>Dados Bancários</Title>
              </Col>
            </Row>
            <Col style={{ paddingLeft: '0' }}>
              <Row style={{ marginLeft: '0' }}>
                <Label color="#0052EF">Selecione o tipo</Label>
              </Row>
              <Row style={{ marginLeft: '0' }}>
                <RadioBox>
                  <Radio
                    disabled={noCNPJ || !noEdit || noEditBank}
                    checked={
                      bankAccount?.bank_person_type === '2' ||
                      bankAccount?.bank_person_type === 2
                    }
                    onChange={() => {
                      setBankAccount({
                        ...bankAccount,
                        bank_person_type: '2',
                      });
                    }}
                  />
                  <span className="radioText">Pessoa Jurídica</span>
                </RadioBox>

                <RadioBox>
                  <Radio
                    disabled={!noEdit || noEditBank}
                    checked={
                      bankAccount?.bank_person_type === 1 ||
                      bankAccount?.bank_person_type === '1'
                    }
                    onChange={() => {
                      setBankAccount({
                        ...bankAccount,
                        bank_person_type: '1',
                      });
                    }}
                  />
                  <span className="radioText">Pessoa Física</span>
                </RadioBox>
              </Row>
            </Col>
            <Col style={{ paddingLeft: '0', marginBottom: '16px' }}>
              <Row style={{ marginLeft: '0' }}>
                {errors?.bank_person_type && (
                  <StyledError>{errors.bank_person_type}</StyledError>
                )}
              </Row>
            </Col>
            <Col style={{ paddingLeft: '0' }}>
              <Row style={{ marginLeft: '0' }}>
                <Label color="#0052EF">tipo de conta</Label>
              </Row>
              <Row style={{ marginLeft: '0' }}>
                <RadioBox>
                  <Radio
                    value="1"
                    checked={
                      (bankAccount?.bank_account_type === '1' && noEdit) ||
                      bankAccount?.bank_account_type === 1
                    }
                    disabled={!noEdit || noEditBank}
                    onChange={e => {
                      setBankAccount({
                        ...bankAccount,
                        bank_account_type: e.target.value,
                      });
                    }}
                  />
                  <span className="radioText">Corrente</span>
                </RadioBox>
                <RadioBox>
                  <Radio
                    value="2"
                    checked={
                      bankAccount?.bank_account_type === '2' ||
                      bankAccount?.bank_account_type === 2
                    }
                    disabled={!noEdit || noEditBank}
                    onChange={e => {
                      setBankAccount({
                        ...bankAccount,
                        bank_account_type: e.target.value,
                      });
                    }}
                  />
                  <span className="radioText">Poupança</span>
                </RadioBox>
              </Row>
            </Col>
            <Col style={{ paddingLeft: '0', marginBottom: '16px' }}>
              <Row style={{ marginLeft: '0' }}>
                {errors?.bank_account_type && (
                  <StyledError>{errors.bank_account_type}</StyledError>
                )}
              </Row>
            </Col>
            <Row>
              <Col xl="3">
                <Field>
                  <Label>Banco</Label>
                  <StyledSelect
                    isDisabled={!noEdit || noEditBank}
                    async={false}
                    defaultValue={banks.find(
                      item => Number(item.value) === bankAccount.bank,
                    )}
                    placeholder="Selecione o seu banco"
                    loadOptions={banks}
                    name="bank"
                    error={errors.bank}
                    onChange={e => {
                      setBankAccount({
                        ...bankAccount,
                        bank: e?.value,
                      });
                    }}
                  />
                  {errors?.bank && <StyledError>{errors.bank}</StyledError>}
                </Field>
              </Col>
              <Col xl="2">
                <Field>
                  <Label>Agência</Label>
                  <InputMask
                    disabled={!noEdit || noEditBank}
                    background={!noEdit || noEditBank}
                    mask={masks[bankAccount.bank - 1]?.ag || '9999'}
                    maskChar={null}
                    block="true"
                    value={bankAccount?.bank_ag}
                    onChange={e => {
                      setBankAccount({
                        ...bankAccount,
                        bank_ag: e.target.value,
                      });
                    }}
                    error={errors?.bank_ag}
                  >
                    {inputProps => {
                      return (
                        <Input
                          disabled={!noEdit || noEditBank}
                          {...inputProps}
                        />
                      );
                    }}
                  </InputMask>
                  {errors?.bank_ag && (
                    <StyledError>{errors.bank_ag}</StyledError>
                  )}
                </Field>
              </Col>
              <Col xl="2">
                <Field>
                  <Label>Conta</Label>
                  <Input
                    disabled={!noEdit || noEditBank}
                    block="true"
                    background={!noEdit || noEditBank}
                    value={bankAccount?.bank_cc?.split('-')[0]}
                    onChange={e => {
                      setBankAccount({
                        ...bankAccount,
                        bank_cc: e.target.value,
                      });
                    }}
                    error={errors?.bank_cc}
                  />
                  {errors?.bank_cc && (
                    <StyledError>A conta é obrigatória.</StyledError>
                  )}
                </Field>
              </Col>
              <Icon>
                <FontAwesomeIcon icon={faMinus} />
              </Icon>
              <Col xl="1">
                <Field>
                  <Label>Dígito</Label>
                  <Input
                    disabled={!noEdit || noEditBank}
                    background={!noEdit || noEditBank}
                    block="true"
                    value={bankAccount?.bank_cc?.split('-')[1]}
                    onChange={e => {
                      setBankAccount({
                        ...bankAccount,
                        bank_cc: `${bankAccount.bank_cc}-${e.target.value}`,
                      });
                    }}
                    error={errors?.bank_cc}
                  />
                  {errors?.bank_cc && (
                    <StyledError>{errors?.bank_cc}</StyledError>
                  )}
                </Field>
              </Col>
            </Row>

            <Col style={{ paddingLeft: '0' }}>
              {bankAccount?.bank_person_type && (
                <>
                  <Row style={{ marginLeft: '0' }}>
                    <Label color="#0052EF">Anexar Documentação</Label>
                  </Row>
                  {bankAccount.bank_person_type === '1' ||
                  bankAccount.bank_person_type === 1 ? (
                    <>
                      <Row>
                        <Col xl="6">
                          <Field>
                            <Label>
                              Documento de Identificação <br />
                              (RG, CNH ou Passaporte)
                            </Label>
                            <Button
                              disabled={!noEdit || noEditBank}
                              onClick={() =>
                                fileInputIdentification.current.click()
                              }
                            >
                              {uploadedFiles.identification
                                ? uploadedFiles.identification
                                : 'Anexar'}
                            </Button>
                            <Input
                              ref={fileInputIdentification}
                              type="file"
                              style={{ display: 'none' }}
                              onChange={e =>
                                handleFileUpload(e, 'identification')
                              }
                            />
                            {errors?.identification && (
                              <StyledError>{errors.identification}</StyledError>
                            )}
                          </Field>
                        </Col>
                        <Col xl="6">
                          <Field>
                            <Label>
                              Selfie do titular <br /> (foto)
                            </Label>
                            <Button
                              disabled={!noEdit || noEditBank}
                              onClick={() => fileInputSelfie.current.click()}
                            >
                              {uploadedFiles.selfie
                                ? uploadedFiles.selfie
                                : 'Anexar'}
                            </Button>
                            <Input
                              ref={fileInputSelfie}
                              type="file"
                              style={{ display: 'none' }}
                              onChange={e => handleFileUpload(e, 'selfie')}
                            />
                            {errors?.selfie && (
                              <StyledError>{errors.selfie}</StyledError>
                            )}
                          </Field>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="6">
                          <Field>
                            <Label>Comprovante de Residência</Label>
                            <Button
                              disabled={!noEdit || noEditBank}
                              onClick={() =>
                                fileInputAddressProof.current.click()
                              }
                            >
                              {uploadedFiles.address_proof
                                ? uploadedFiles.address_proof
                                : 'Anexar'}
                            </Button>
                            <Input
                              ref={fileInputAddressProof}
                              type="file"
                              style={{ display: 'none' }}
                              onChange={e =>
                                handleFileUpload(e, 'address_proof')
                              }
                            />
                            {errors?.address_proof && (
                              <StyledError>{errors.address_proof}</StyledError>
                            )}
                          </Field>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    (bankAccount.bank_person_type === '2' ||
                      bankAccount.bank_person_type === 2) && (
                      <>
                        <Row>
                          <Col xl="6">
                            <Field>
                              <Label>
                                Documento de Identificação do Representante
                                Legal <br />
                                (RG, CNH ou Passaporte)
                              </Label>
                              <Button
                                disabled={!noEdit || noEditBank}
                                onClick={() =>
                                  fileInputIdentification.current.click()
                                }
                              >
                                {uploadedFiles.identification
                                  ? uploadedFiles.identification
                                  : 'Anexar'}
                              </Button>
                              <Input
                                ref={fileInputIdentification}
                                type="file"
                                style={{ display: 'none' }}
                                onChange={e =>
                                  handleFileUpload(e, 'identification')
                                }
                              />
                              {errors?.identification && (
                                <StyledError>
                                  {errors.identification}
                                </StyledError>
                              )}
                            </Field>
                          </Col>
                          <Col xl="6">
                            <Field>
                              <Label>
                                Selfie do Representante Legal <br /> (foto)
                              </Label>
                              <Button
                                disabled={!noEdit || noEditBank}
                                onClick={() => fileInputSelfie.current.click()}
                              >
                                {uploadedFiles.selfie
                                  ? uploadedFiles.selfie
                                  : 'Anexar'}
                              </Button>
                              <Input
                                ref={fileInputSelfie}
                                type="file"
                                style={{ display: 'none' }}
                                onChange={e => handleFileUpload(e, 'selfie')}
                              />
                              {errors?.selfie && (
                                <StyledError>{errors.selfie}</StyledError>
                              )}
                            </Field>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6">
                            <Field>
                              <Label>Comprovante de Endereço da Empresa</Label>
                              <Button
                                disabled={!noEdit || noEditBank}
                                onClick={() =>
                                  fileInputAddressProof.current.click()
                                }
                              >
                                {uploadedFiles.address_proof
                                  ? uploadedFiles.address_proof
                                  : 'Anexar'}
                              </Button>
                              <Input
                                ref={fileInputAddressProof}
                                type="file"
                                style={{ display: 'none' }}
                                onChange={e =>
                                  handleFileUpload(e, 'address_proof')
                                }
                              />
                              {errors?.address_proof && (
                                <StyledError>
                                  {errors.address_proof}
                                </StyledError>
                              )}
                            </Field>
                          </Col>
                          <Col xl="6">
                            <Field>
                              <Label>
                                Faturamento dos Últimos 6 Meses <br />
                                (assinado e carimbado pelo contador)
                              </Label>
                              <Button
                                disabled={!noEdit || noEditBank}
                                onClick={() =>
                                  fileInputBalanceSheet.current.click()
                                }
                              >
                                {uploadedFiles.balance_sheet
                                  ? uploadedFiles.balance_sheet
                                  : 'Anexar'}
                              </Button>
                              <Input
                                ref={fileInputBalanceSheet}
                                type="file"
                                style={{ display: 'none' }}
                                onChange={e =>
                                  handleFileUpload(e, 'balance_sheet')
                                }
                              />
                              {errors?.balance_sheet && (
                                <StyledError>
                                  {errors.balance_sheet}
                                </StyledError>
                              )}
                            </Field>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="6">
                            <Field>
                              <Label>
                                Contrato Social ou Documento de Constituição
                              </Label>
                              <Button
                                disabled={!noEdit || noEditBank}
                                onClick={() =>
                                  fileInputSocialContract.current.click()
                                }
                              >
                                {uploadedFiles.social_contract
                                  ? uploadedFiles.social_contract
                                  : 'Anexar'}
                              </Button>
                              <Input
                                ref={fileInputSocialContract}
                                type="file"
                                style={{ display: 'none' }}
                                onChange={e =>
                                  handleFileUpload(e, 'social_contract')
                                }
                              />
                              {errors?.social_contract && (
                                <StyledError>
                                  {errors.social_contract}
                                </StyledError>
                              )}
                            </Field>
                          </Col>
                        </Row>
                      </>
                    )
                  )}
                </>
              )}
            </Col>

            <Col style={{ paddingLeft: '0', marginBottom: '16px' }}>
              <Row style={{ marginLeft: '0' }}>
                {(noCNPJ && noEdit) ||
                (bankAccount?.bank_person_type === '1' && noEdit) ? (
                  <div className="warning">
                    <FontAwesomeIcon icon={faExclamationCircle} />
                    <span>
                      Vamos utilizar o CPF do responsável como titular do
                      destino. Portanto, a conta bancária <br /> precisa ser do
                      responsável.
                    </span>
                  </div>
                ) : null}
                {!noEdit && (
                  <div className="alert">
                    <FontAwesomeIcon icon={faExclamationCircle} />
                    <span>
                      Para inserir os dados bancários é necessário salvar os
                      dados das informações financeiras <br /> acima.
                    </span>
                  </div>
                )}
              </Row>
            </Col>
          </>
        </ContentWrapper>
      )}
      <FooterWrapper>
        {!modal && (
          <Button
            style={{
              marginLeft: 'auto',
            }}
            onClick={() => (noEdit ? setUpdateModal(true) : updateBilling())}
            disabled={!noEdit || billingLoading}
            icon
            outline={!noEditBank}
          >
            {(billingLoading && noEdit) || (bankLoading && noEditBank) ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              'Alterar dados'
            )}
          </Button>
        )}
        <Button
          onClick={!noEdit ? updateBilling : updateBankAccount}
          disabled={bankLoading || (noEditBank && noEdit)}
          icon
          outline={noEditBank && noEdit}
        >
          {(billingLoading && !noEdit) || (bankLoading && !noEditBank) ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            'Salvar dados'
          )}
        </Button>
      </FooterWrapper>
    </ContainerWrapper>
  );
};

export default Form;
