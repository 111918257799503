import React from 'react';
import { ProgressLine, ProgressBar, ProgressContainer, Label } from './styles';

interface ProgressProperties {
  status: number;
}

const Progress: React.FC<ProgressProperties> = ({ status }) => (
  <ProgressContainer>
    <Label>
      {status === 0
        ? 'NA FILA 0%'
        : status === 1
        ? 'SINCRONIZANDO 15%'
        : status === 2
        ? 'CONFIGURANDO 50%'
        : status === 3
        ? 'VALIDANDO 90%'
        : status >= 4
        ? 'FINALIZADO 100%'
        : null}
    </Label>
    <ProgressBar />
    <ProgressLine status={status} />
  </ProgressContainer>
);

export default Progress;
