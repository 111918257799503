import React from 'react';

import { Container } from './styles';
import Tab from './Tab';

function VerticalTabs({ items }) {
  /*
    items = [
      {name: {Component}, content: {Component}}
    ]
  */

  return (
    <Container>
      {items?.map(({ name: Name, content: Content, defaultOpen }, id) => (
        <Tab key={id} name={Name} defaultOpen={defaultOpen}>
          <Content />
        </Tab>
      ))}
    </Container>
  );
}

export default VerticalTabs;
